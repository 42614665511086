import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:flex-row w-full justify-between py-3 items-center gap-2" }
const _hoisted_2 = { class: "sm:max-w-[80%] text-center sm:text-left lg:max-w-auto relative" }
const _hoisted_3 = { class: "h5 leading-5" }
const _hoisted_4 = { class: "align-text-top text-center sm:text-left opacity-60" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_6 = { class: "flex fle-col relative h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_progress_bar = _resolveComponent("file-progress-bar")!
  const _component_primary_button_link = _resolveComponent("primary-button-link")!
  const _component_basic_button = _resolveComponent("basic-button")!
  const _component_drop_file_context_menu = _resolveComponent("drop-file-context-menu")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_file_progress_bar, {
        progress: _ctx.dropFile.kind === 'uploadingDropFile' ? _ctx.dropFile.dropFile.progress : 0,
        visible: _ctx.dropFile.kind === 'uploadingDropFile'
      }, null, 8, ["progress", "visible"]),
      _createElementVNode("section", {
        class: _normalizeClass(`transition-all transform
			${_ctx.dropFile.kind === 'uploadingDropFile' ? 'transform translate-x-[50px]' : ''}`)
      }, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.filename), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.displaySize), 1)
      ], 2)
    ]),
    (_ctx.dropFile.kind === 'dropFile')
      ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
          _createVNode(_component_primary_button_link, { to: _ctx.downloadLink }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Download")
            ])),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("section", _hoisted_6, [
            _createVNode(_component_basic_button, { onClick: _ctx.openContextMenu }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("More")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_Transition, { name: "fade-fast" }, {
              default: _withCtx(() => [
                (_ctx.isContextMenuOpen)
                  ? (_openBlock(), _createBlock(_component_drop_file_context_menu, {
                      key: 0,
                      "drop-id": _ctx.dropFile.dropFile.dropId,
                      "file-id": _ctx.dropFile.dropFile.id
                    }, null, 8, ["drop-id", "file-id"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}