import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "absolute w-[43px] h-[43px]" }
const _hoisted_3 = {
  width: "43px",
  height: "43px",
  viewBox: "0 0 43 43"
}
const _hoisted_4 = ["d"]
const _hoisted_5 = ["d"]
const _hoisted_6 = { class: "absolute w-[43px] h-[43px] flex flex-row justify-center items-center text-sm font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "slide-out-left-fade" }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              (_openBlock(), _createElementBlock("svg", _hoisted_3, [
                _createElementVNode("path", {
                  class: "stroke-primary-900 fill-surface-900 origin-center stroke-[3] transform -scale-y-100 translate-x-[1px] -translate-y-[1px]",
                  d: _ctx.fullCirclePath
                }, null, 8, _hoisted_4),
                _createElementVNode("path", {
                  class: "stroke-primary-600 fill-transparent origin-center stroke-[3] circle-transition transform -scale-y-100 translate-x-[1px] -translate-y-[1px]",
                  d: _ctx.path
                }, null, 8, _hoisted_5)
              ]))
            ]),
            _createElementVNode("section", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.progress), 1),
              _cache[0] || (_cache[0] = _createElementVNode("p", null, "%", -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}