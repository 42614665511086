<template>
<main class="bg-surface-100-900 h-[calc(100vh-80px)] sm:h-[calc(100vh-100px)] overflow-hidden">
		<section class="flex flex-col max-w-[26rem] m-auto mt-4 px-5" v-if="!success">
			<h1 class="h3">Reset password</h1>
			<h2 class="h6 opacity-60">Enter a new password for your account</h2>

			<section class="flex flex-col gap-4 mt-4">
				<titled-filled-field title="Password" field-type="password" placeholder="Password"
									v-model="password"/>
				<titled-filled-field title="Confirm password" field-type="password"
									placeholder="Confirm password"
									v-model="verifyPassword"/>
				<primary-button class="mt-2" @click="resetPassword">Reset password</primary-button>
			</section>
		</section>

		<section class="flex flex-col mt-4 m-auto max-w-[26rem] px-5" v-else>
			<h1 class="h4">Password reset successfully</h1>
			<h2 class="h6 opacity-60">Please log in with your new password</h2>
			<primary-button class="mt-4" @click="$router.push('/')">Homepage</primary-button>
		</section>
</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TitledFilledField from '@/ui/components/core/text_fields/titled_filled_field.vue';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';
import { Api } from '@/services';
import { BasicError } from '@/interfaces/models';
import Axios, { AxiosError } from 'axios';
import { Router } from 'vue-router';

export default defineComponent({
  name: 'reset_password',
  components: {
    PrimaryButton,
    TitledFilledField,
  },
  data() {
    return {
      password: '',
      verifyPassword: '',
      token: this.$route.params.token as string,
      success: false,
    };
  },
  async mounted() {
    try {
      await Api.getResetPasswordLink(this.token);
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        if (e?.response?.status === 404) {
          this.$store.dispatch('pushErrorNotification', 'This link is not valid. Please create a new one and try again');
        } else {
          this.$store.dispatch('pushErrorNotification', 'Unknown error occurred while verifying link. Please create a new one or try again later');
        }
        await this.$router.push('/');
      } else {
        this.$store.dispatch('pushErrorNotification', 'Unknown error occurred.');
      }
    }
  },
  methods: {
    resetPassword() {
      if (this.password === '') {
        this.$store.dispatch('pushErrorNotification', 'Password cannot be empty');
        return;
      }
      if (this.password !== this.verifyPassword) {
        this.$store.dispatch('pushErrorNotification', 'Password do not match');
        return;
      }

      try {
        Api.resetPassword({
          token: this.token,
          password: this.password,
          verifyPassword: this.verifyPassword,
        });
        this.$store.dispatch('pushSuccessNotification', 'Password has been reset successfully');
        this.success = true;
      } catch (e) {
        if ((e as AxiosError | null)?.response?.data !== undefined) {
          const response = (e as AxiosError).response!.data as BasicError;
          this.$store.dispatch('pushErrorNotification', `Failed to reset password. ${response.reason}`);
        } else {
          this.$store.dispatch('pushErrorNotification', 'Unknown error occurred while resetting password. Please try again later.');
        }
      }
    },
  },
});
</script>

<style scoped>

</style>
