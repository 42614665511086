<template>
<div class="card bg-surface-950 p-5 absolute top-8 right-0 flex flex-col items-center space-y-4 z-40">

	<section class="flex flex-row space-x-4 justify-center items-center">
		<section>
			<i class="fas fa-user-circle fa-3x"></i>
		</section>
		<section>
			<h1 class="h5 font-bold">{{ this.$store.state.user.currentUser.username }}</h1>
			<h3>{{ this.$store.state.user.currentUser.email }}</h3>
		</section>
	</section>

	<section class="flex flex-col items-stretch w-full divide-y divide-surface-600 [&>span]:grid">
		<span><basic-button @click="openAccountSettings">Account settings</basic-button></span>
		<span><basic-button @click="openMyDrops">My drops</basic-button></span>
		<span><basic-button-error @click="signOut">Sign out</basic-button-error></span>
	</section>

</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import BasicButtonError from '@/ui/components/core/buttons/basic_button_error.vue';

export default defineComponent({
	name: 'UserDialog',
	components: { BasicButton, BasicButtonError },
	methods: {
		openAccountSettings() {
			this.$emit('optionSelect');
			this.$router.push('/profile/account');
		},
		openMyDrops() {
			this.$emit('optionSelect');
			this.$router.push('/profile/drops');
		},
		signOut() {
			this.$emit('optionSelect');
			this.$store.dispatch('logout');
		},
	},
});
</script>
