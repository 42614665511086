<template>
<div>
	<h1 class="h3">Register</h1>
	<section class="flex flex-col items-center space-y-2.5 mt-4">
		<filled-text-field v-model="username" :disable="disableControls" placeholder="Username" />
		<filled-text-field v-model="email" :disable="disableControls" placeholder="Email"
							type="email" />
		<filled-text-field v-model="password" :disable="disableControls" placeholder="Password"
							type="password" />
		<filled-text-field v-model="verifyPassword" :disable="disableControls"
							placeholder="Confirm password" type="password" />
	</section>
	<section class="flex flex-row justify-stretch mt-6">
		<primary-button @click="register" :disable="disableControls" >Register</primary-button>
		<basic-button @click="cancel">Cancel</basic-button>
	</section>
	<section v-if="$store.state.user.error.isError">
		<p class="text-red-600 text-center py-3 text-sm">
		{{ $store.state.user.error.message }}
		</p>
	</section>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FilledTextField from '@/ui/components/core/text_fields/filled_text_field.vue';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';

export default defineComponent({
  name: 'RegisterDialogContent',
  components: {
    BasicButton,
    PrimaryButton,
    FilledTextField,
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      verifyPassword: '',
    };
  },
  computed: {
    disableControls() {
      return this.$store.state.user.isLoading;
    },
  },
  methods: {
    register() {
      this.$store.dispatch('createUser', {
        username: this.username,
        email: this.email,
        password: this.password,
        verifyPassword: this.verifyPassword,
      });
    },
    cancel() {
      this.$emit('cancelRegister');
    },
  },
});
</script>

<style scoped>

</style>
