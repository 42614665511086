<template>
<section class="grid">
	<h3 class="h6">{{ $props.title }}</h3>
	<section class="pl-4 sm:justify-self-end w-full lg:w-4/5"> 
		<slot />
	</section>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ProfileSettingsSection',
	props: {
		title: String,
	},
});
</script>
