import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card flex flex-col" }
const _hoisted_2 = { class: "flex flex-col gap-y-1 items-stretch divide-y divide-surface-500 [&>span]:grid" }
const _hoisted_3 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_button = _resolveComponent("basic-button")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "h3 px-3" }, "Profile", -1)),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_basic_button, {
          class: "btn-lg",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$router.push('/profile/account'))),
          toggled: _ctx.currentRouteName === 'accountSettings'
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Account settings ")
          ])),
          _: 1
        }, 8, ["toggled"])
      ]),
      _createElementVNode("span", null, [
        _createVNode(_component_basic_button, {
          class: "btn-lg",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$router.push('/profile/drops'))),
          toggled: _ctx.currentRouteName === 'myDrops'
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" My drops ")
          ])),
          _: 1
        }, 8, ["toggled"])
      ])
    ])
  ]))
}