<template>
	<button :disabled="disable"
			class="btn btn-md text-error-600 transition-colors  
				hover:preset-filled-error-500
				focus:preset-filled-error-500
				active:bg-error-900
				disabled:bg-error-950 disabled:text-opacity-50 disabled:brightness-75">
		<slot/>
	</button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BasicButtonError',
	props: {
		disable: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style scoped>

</style>
