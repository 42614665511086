import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:flex-row sm:justify-between items-center gap-2 border-b border-surface-700 pb-3" }
const _hoisted_2 = { class: "input-group divide-surface-700 divide-x max-w-80 grid grid-cols-[3fr,1fr] sm:w-full m-auto sm:m-0" }
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_field = _resolveComponent("text-field")!
  const _component_primary_button_error = _resolveComponent("primary-button-error")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_field, {
        class: "input-group-cell",
        onClick: _ctx.onLinkClick,
        ref: "linkField",
        value: _ctx.link
      }, null, 8, ["onClick", "value"]),
      _createElementVNode("a", {
        class: "input-group-cell transition-colors hover:preset-filled-primary-500 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyLink && _ctx.copyLink(...args)))
      }, "Copy")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_primary_button_error, { onClick: _ctx.deleteDrop }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Delete drop")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}