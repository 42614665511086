<template>
<div class="card absolute bg-surface-950 top-10 right-0 z-10 shadow-lg overflow-hidden bg-opacity-60 backdrop-blur-md">
	<ul class="flex flex-col z-30 items-stretch divide-y divide-surface-600">
		<span><context-menu-item icon="fas fa-eye" @click="openPreview">Preview</context-menu-item></span>
		<span><context-menu-item icon="fas fa-trash-alt" :danger="true"
							@click="deleteFile">Delete</context-menu-item></span>
	</ul>
</div>
</template>

<script>
import ContextMenuItem from '@/ui/components/core/context_menus/context_menu_item.vue';

export default {
  name: 'DropFileContextMenu',
  components: { ContextMenuItem },
  props: {
    dropId: String,
    fileId: Number,
  },
  methods: {
    openPreview() {
      this.$store.dispatch('showFilePreview', this.$props.fileId);
    },
    deleteFile() {
      this.$store.dispatch('deleteFile', this.$props.fileId);
    },
  },
};
</script>

<style scoped>

</style>
