<template>
<nav class="card flex flex-col">
	<h1 class="h3 px-3">Profile</h1>
	<ul class="flex flex-col gap-y-1 items-stretch divide-y divide-surface-500 [&>span]:grid">
	
		<span class="w-full"><basic-button 
			class="btn-lg"
			@click="this.$router.push('/profile/account')" 
			:toggled="currentRouteName === 'accountSettings'"
		>
			Account settings
		</basic-button></span>
		<span><basic-button 
			class="btn-lg"
			@click="this.$router.push('/profile/drops')" 
			:toggled="currentRouteName === 'myDrops'"
		>
			My drops
		</basic-button></span>

	</ul>
</nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';

export default defineComponent({
	name: 'ProfileSidebar',
	components: { BasicButton },
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
	},
	methods: {

	}
});
</script>
