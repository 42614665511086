<template>
<li :class="`btn flex flex-row items-center gap-x-2 h-14
				hover:cursor-pointer
				hover:${containerHoverColor} active:${containerActiveColor}`">
	<div class="flex flex-row justify-center items-center w-7 h-7">
		<i :class="`${icon}`"></i>
	</div>
	<slot />
</li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContextMenuItem',
  props: {
    icon: String,
    danger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerHoverColor() {
      if (this.$props.danger) return 'bg-error-800 text-error-contrast-800';
      return 'bg-surface-800';
    },
    containerActiveColor() {
      if (this.$props.danger) return 'bg-error-900';
      return 'bg-violet-900';
    },
  },
});
</script>

<style scoped>

</style>
