import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disable,
    class: "btn btn-md text-error-600 transition-colors hover:preset-filled-error-500 focus:preset-filled-error-500 active:bg-error-900 disabled:bg-error-950 disabled:text-opacity-50 disabled:brightness-75"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}