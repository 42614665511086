import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-surface-950 w-72 flex flex-row gap-x-3 px-4 py-3 card drop-shadow-xl" }
const _hoisted_2 = { class: "flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      _createElementVNode("i", {
        class: _normalizeClass(`${_ctx.icon} fa-lg ${_ctx.primaryTextColor}`)
      }, null, 2)
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.primaryTextColor)
      }, _toDisplayString(_ctx.$props.notification.title), 3),
      _createElementVNode("p", null, _toDisplayString(_ctx.$props.notification.content), 1)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeNotification && _ctx.closeNotification(...args)))
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("i", { class: "fas fa-times hover:opacity-60" }, null, -1)
      ]))
    ])
  ]))
}