<template>
	<header class="flex flex-col sm:flex-row sm:justify-between items-center gap-2 border-b border-surface-700 pb-3">
		<div class="input-group divide-surface-700 divide-x max-w-80 grid grid-cols-[3fr,1fr] sm:w-full m-auto sm:m-0">
			<text-field class="input-group-cell" @click="onLinkClick" ref="linkField" :value="link" />
			<a class="input-group-cell transition-colors hover:preset-filled-primary-500 cursor-pointer" @click="copyLink">Copy</a>
		</div>
		<div class="">
			<primary-button-error @click="deleteDrop">Delete drop</primary-button-error>
		</div>
	</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TextField from '@/ui/components/core/text_fields/text_field.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import PrimaryButtonError from '@/ui/components/core/buttons/primary_button_error.vue';

export default defineComponent({
	name: 'drop-header',
	components: {
		PrimaryButtonError, BasicButton, TextField,
	},
	props: {
		dropId: String,
	},
	data() {
		return {
			hasSelectedLink: false,
		};
	},
	computed: {
		link() {
			return `${process.env.VUE_APP_URL}/${this.dropId}`;
		},
	},
	methods: {
		copyLink() {
			const linkField = this.$refs.linkField as typeof TextField;
			linkField.selectAndCopy();
			this.hasSelectedLink = true;
		},
		onLinkClick() {
			if (this.hasSelectedLink) return;

			const linkField = this.$refs.linkField as typeof TextField;
			linkField.selectText();
			this.hasSelectedLink = true;
		},
		deleteDrop() {
			this.$store.dispatch('deleteDrop');
		},
	},
});
</script>

<style scoped>

</style>
