import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sign_in_dialog_content = _resolveComponent("sign-in-dialog-content")!
  const _component_register_dialog_content = _resolveComponent("register-dialog-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: "card bg-surface-950 p-5 absolute top-8 right-0 flex flex-col items-center z-40 transition-[height] ease-in-out duration-400",
    style: _normalizeStyle(_ctx.rootStyle)
  }, [
    _createVNode(_Transition, {
      name: _ctx.transitionName,
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (!_ctx.isRegistering)
          ? (_openBlock(), _createBlock(_component_sign_in_dialog_content, {
              onRegister: _ctx.register,
              key: "sign-in"
            }, null, 8, ["onRegister"]))
          : (_openBlock(), _createBlock(_component_register_dialog_content, {
              onCancelRegister: _ctx.cancelRegister,
              key: "register"
            }, null, 8, ["onCancelRegister"]))
      ]),
      _: 1
    }, 8, ["name"])
  ], 4))
}