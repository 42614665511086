<template>
<div 
	v-if="$store.state.myDrops.error.isError"
	class="flex flex-col items-center gap-y-3 my-10"
>
	<i class="fas fa-exclamation-triangle fa-3x"></i>
	<h6 class="h6">Failed to load my drops</h6>
	<primary-button @click="loadDrops">Retry</primary-button>
</div>

<div 
	v-else-if="$store.state.myDrops.drops === null"
	class="flex flex-col items-center my-10"
>
    <progress-spinner :size="10" />
    <h6 class="h6">Loading my drops</h6>
</div>

<div 
	v-else-if="$store.state.myDrops.drops.length === 0"
	class="flex flex-col items-center gap-y-3 my-10"
>
	<h6 class="h6">You currently have no active drops</h6>
</div>

<ul v-else class="flex flex-col divide-y-2 divide-surface-700">
	<my-drop-list-item v-for="drop of drops" :key="drop.id" :drop-id="drop.id" :create-date="drop.createDate" :link="`/${drop.id}`" />
</ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileSection from '@/ui/components/profile/profile_section.vue';
import MyDropListItem from '@/ui/components/profile/drops/my_drops_list_item.vue';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';
import ProgressSpinner from '@/ui/components/core/progress_bars/progress_spinner.vue';
import { Drop } from '@/interfaces/models';

export default defineComponent({
  name: 'MyDropsSection',
  components: {
    ProgressSpinner,
    PrimaryButton,
    MyDropListItem,
    ProfileSection,
  },
  mounted() {
    this.loadDrops();
  },
  computed: {
    drops(): Drop[] {
      return this.$store.state.myDrops.drops;
    },
  },
  methods: {
    loadDrops() {
      this.$store.dispatch('loadMyDrops');
    },
  },
});
</script>

<style scoped>

</style>
