import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col text-center sm:text-left sm:flex-row w-full justify-between py-3 gap-y-2" }
const _hoisted_2 = { class: "flex flex-col transition-all" }
const _hoisted_3 = { class: "h4 sm:h5" }
const _hoisted_4 = { class: "align-text-top leading-4 opacity-60" }
const _hoisted_5 = { class: "flex flex-col sm:flex-row gap-2 sm:items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_primary_button_link = _resolveComponent("primary-button-link")!
  const _component_basic_button_error = _resolveComponent("basic-button-error")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.dropId), 1),
      _createElementVNode("p", _hoisted_4, "Created on: " + _toDisplayString(_ctx.createDate.toLocaleString('en-uk')), 1)
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(_component_primary_button_link, { to: _ctx.link }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("View")
        ])),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_basic_button_error, { onClick: _ctx.deleteDrop }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Delete")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}