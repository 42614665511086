<template>
<div class="w-full bg-surface-100-900 px-2 sm:px-6 lg:px-12 py-3 h-[calc(100vh-80px)] sm:h-[calc(100vh-100px)] overflow-hidden">

	<div v-if="isDropLoaded"
		class="relative h-full max-w-[1400px] mx-auto" 
	>
		<drop-header :drop-id="drop.id" />
		<section class="overflow-y-auto overflow-x-hidden sm:h-[calc(100%-80px)] h-[calc(100%-100px)]"> 
			<drop-file-list :files="files" />
		</section>
		<file-preview-dialog />
	</div>

	<div v-else-if="dropError.isError"
		class="flex flex-col justify-center items-center h-full space-y-3"
	>
		<i class="fas fa-exclamation-triangle fa-3x"></i>
		<h6 class="h6">{{ dropError.message ?? 'Error occurred while loading drop' }}</h6>
		<primary-button @click="$router.push('/')">Go to homepage</primary-button> 
	</div>

	<div v-else 
		class="flex flex-col justify-center items-center h-full w-full space-y-3"
	>
		<progress-spinner :size="10" />
		<h6 class="h6">Loading drop</h6>
	</div>

</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Drop } from '@/interfaces/models/drop';
import DropHeader from '@/ui/components/core/headers/drop_header.vue';
import DropFileList from '@/ui/components/core/lists/drop_file_list.vue';
import { AnyDropFile } from '@/interfaces/store/drop';
import ProgressSpinner from '@/ui/components/core/progress_bars/progress_spinner.vue';
import { ErrorState } from '@/interfaces/store/shared';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';
import FilePreviewDialog from '@/ui/components/drop/file_preview_dialog.vue';

export default defineComponent({
  name: 'drop-container',
  components: {
    FilePreviewDialog,
    PrimaryButton,
    ProgressSpinner,
    DropFileList,
    DropHeader,
  },
  computed: {
    isDropLoaded(): boolean {
      return this.$store.state.drop.drop !== null;
    },
    drop(): Drop {
      return this.$store.state.drop.drop;
    },
    files(): AnyDropFile[] {
      return this.$store.state.drop.dropFiles;
    },
    dropError(): ErrorState {
      return this.$store.state.drop.error;
    },
  },
});

</script>

<style scoped>

</style>
