import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 preset-tonal-surface w-80 sm:w-96 flex flex-col gap-y-5 px-8 pt-4 pb-4 shadow-lg"
}
const _hoisted_2 = { class: "flex flex-col gap-y-2" }
const _hoisted_3 = { class: "h3" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "flex flex-row gap-x-4 justify-end h-10 items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_primary_button = _resolveComponent("primary-button")!
  const _component_primary_button_error = _resolveComponent("primary-button-error")!
  const _component_basic_button = _resolveComponent("basic-button")!

  return (_openBlock(), _createBlock(_Transition, { name: "upscale-down" }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$props.title), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$props.text), 1)
            ]),
            _createElementVNode("section", _hoisted_5, [
              (!_ctx.$props.destructive)
                ? (_openBlock(), _createBlock(_component_primary_button, {
                    key: 0,
                    onClick: _ctx.confirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$props.confirmTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_primary_button_error, {
                    key: 1,
                    onClick: _ctx.confirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$props.confirmTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])),
              _createVNode(_component_basic_button, { onClick: _ctx.cancel }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}