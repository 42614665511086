import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-5" }
const _hoisted_2 = { class: "[&>div]:grid [&>div]:grid-cols-1 sm:[&>div]:grid-cols-2 grid gap-y-5" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "self-center m-1" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "self-center m-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_edit_field = _resolveComponent("account-edit-field")!
  const _component_setting_section = _resolveComponent("setting-section")!
  const _component_basic_button = _resolveComponent("basic-button")!
  const _component_basic_button_error = _resolveComponent("basic-button-error")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_setting_section, { title: "User info" }, {
        default: _withCtx(() => [
          _createVNode(_component_account_edit_field, {
            class: "mb-4",
            title: "Username",
            modelValue: _ctx.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
            editing: _ctx.editingField === 1,
            onEdit: _ctx.editUsername,
            onCancel: _ctx.cancelEdit,
            onSave: _ctx.saveUsername
          }, null, 8, ["modelValue", "editing", "onEdit", "onCancel", "onSave"]),
          _createVNode(_component_account_edit_field, {
            title: "Email",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
            editing: _ctx.editingField === 2,
            onEdit: _ctx.editEmail,
            onCancel: _ctx.cancelEdit,
            onSave: _ctx.saveEmail
          }, null, 8, ["modelValue", "editing", "onEdit", "onCancel", "onSave"])
        ]),
        _: 1
      }),
      _createVNode(_component_setting_section, { title: "User actions" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("section", null, [
                _createElementVNode("h5", { class: "" }, "Reset password"),
                _createElementVNode("p", { class: "opacity-60" }, "Send reset link with email")
              ], -1)),
              _createElementVNode("section", _hoisted_4, [
                _createVNode(_component_basic_button, {
                  class: "w-full sm:max-w-[200px]",
                  onClick: _ctx.createResetPasswordLink
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Reset password")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("section", null, [
                _createElementVNode("h5", { class: "" }, "Delete account"),
                _createElementVNode("p", { class: "opacity-60" }, "Delete all user data and settings")
              ], -1)),
              _createElementVNode("section", _hoisted_6, [
                _createVNode(_component_basic_button_error, {
                  class: "w-full sm:max-w-[200px]",
                  onClick: _ctx.showDeleteAccountConfirm
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Delete")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_confirm_dialog, {
      title: "Confirm",
      text: "Are you sure you want to delete your account?\n                    This will erase all user data as well as all your drops.",
      destructive: true,
      "confirm-title": "Delete",
      onCancelConfirm: _ctx.cancelDeleteAccount,
      onConfirm: _ctx.deleteAccount,
      visible: _ctx.confirmDeleteAccountVisible
    }, null, 8, ["onCancelConfirm", "onConfirm", "visible"])
  ], 64))
}