<template>
<div class="flex flex-col gap-y-5">
    <setting-section title="User info">
        <account-edit-field
			class="mb-4"
			title="Username" v-model="username" :editing="editingField === 1"
			@edit="editUsername" @cancel="cancelEdit" @save="saveUsername"/>
        <account-edit-field
			title="Email" v-model="email" :editing="editingField === 2"
			@edit="editEmail" @cancel="cancelEdit" @save="saveEmail"/>
    </setting-section>

    <setting-section title="User actions">
		<div class="[&>div]:grid [&>div]:grid-cols-1 sm:[&>div]:grid-cols-2 grid gap-y-5">
			<div class="">
				<section>
					<h5 class="">Reset password</h5>
					<p class="opacity-60">Send reset link with email</p>
				</section>
				<section class="self-center m-1">
					<basic-button class="w-full sm:max-w-[200px]" @click="createResetPasswordLink">Reset password</basic-button>
				</section>
			</div>

			<div class="">
				<section>
					<h5 class="">Delete account</h5>
					<p class="opacity-60">Delete all user data and settings</p>
				</section>
				<section class="self-center m-1">
					<basic-button-error class="w-full sm:max-w-[200px]" @click="showDeleteAccountConfirm">Delete</basic-button-error>
				</section>
			</div>
		</div>
    </setting-section>
</div>
    <!--eslint-disable-next-line-->
    <confirm-dialog title="Confirm"
                    text="Are you sure you want to delete your account?
                    This will erase all user data as well as all your drops."
                    :destructive="true" confirm-title="Delete" @cancel-confirm="cancelDeleteAccount"
                    @confirm="deleteAccount" :visible="confirmDeleteAccountVisible"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileSection from '@/ui/components/profile/profile_section.vue';
import SettingSection from '@/ui/components/profile/setting_section.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import BasicButtonError from '@/ui/components/core/buttons/basic_button_error.vue';
import AccountEditField from '@/ui/components/profile/account_settings/account_edit_field.vue';
import ConfirmDialog from '@/ui/components/core/dialogs/confirm_dialog.vue';
import { Api } from '@/services';
import Primary_button from '../../core/buttons/primary_button.vue';

enum EditingField {
  None,
  Username,
  Email
}

export default defineComponent({
  name: 'AccountSettingsSection',
  components: {
    ConfirmDialog,
    AccountEditField,
    BasicButtonError,
    BasicButton,
    SettingSection,
    ProfileSection,
    Primary_button,
  },
  data() {
    return {
      username: this.$store.state.user.currentUser.username,
      email: this.$store.state.user.currentUser.email,
      editingField: EditingField.None as EditingField,
      confirmDeleteAccountVisible: false,
    };
  },
  computed: {
    currentUsername(): string {
      return this.$store.state.user.currentUser.username;
    },
    currentEmail(): string {
      return this.$store.state.user.currentUser.email;
    },
  },
  methods: {
    cancelCurrentEdit() {
      switch (this.editingField) {
        case EditingField.Username:
          this.username = this.currentUsername;
          break;
        case EditingField.Email:
          this.email = this.currentEmail;
          break;
        default:
          break;
      }
    },
    cancelEdit() {
      this.cancelCurrentEdit();
      this.editingField = EditingField.None;
    },
    editUsername() {
      this.cancelCurrentEdit();
      this.editingField = EditingField.Username;
    },
    editEmail() {
      this.cancelCurrentEdit();
      this.editingField = EditingField.Email;
    },
    async saveUsername() {
      await this.$store.dispatch('updateUsername', this.username);
      this.cancelCurrentEdit();
      this.editingField = EditingField.None;
    },
    async saveEmail() {
      await this.$store.dispatch('updateEmail', this.email);
      this.cancelCurrentEdit();
      this.editingField = EditingField.None;
    },
    showDeleteAccountConfirm() {
      this.confirmDeleteAccountVisible = true;
    },
    cancelDeleteAccount() {
      this.confirmDeleteAccountVisible = false;
    },
    deleteAccount() {
      this.$store.dispatch('deleteAccount');
    },
    async createResetPasswordLink() {
      try {
        await Api.createResetPasswordLink();
        this.$store.dispatch(
          'pushSuccessNotification',
          'Password reset link has been sent to your email',
        );
      } catch (e) {
        this.$store.dispatch(
          'pushErrorNotification',
          'Unexpected error occurred. Failed to send password reset link',
        );
      }
    },
  },
});
</script>
