import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card bg-surface-950 p-5 absolute top-8 right-0 flex flex-col items-center space-y-4 z-40" }
const _hoisted_2 = { class: "flex flex-row space-x-4 justify-center items-center" }
const _hoisted_3 = { class: "h5 font-bold" }
const _hoisted_4 = { class: "flex flex-col items-stretch w-full divide-y divide-surface-600 [&>span]:grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_button = _resolveComponent("basic-button")!
  const _component_basic_button_error = _resolveComponent("basic-button-error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("section", null, [
        _createElementVNode("i", { class: "fas fa-user-circle fa-3x" })
      ], -1)),
      _createElementVNode("section", null, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(this.$store.state.user.currentUser.username), 1),
        _createElementVNode("h3", null, _toDisplayString(this.$store.state.user.currentUser.email), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("span", null, [
        _createVNode(_component_basic_button, { onClick: _ctx.openAccountSettings }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Account settings")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("span", null, [
        _createVNode(_component_basic_button, { onClick: _ctx.openMyDrops }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("My drops")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("span", null, [
        _createVNode(_component_basic_button_error, { onClick: _ctx.signOut }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Sign out")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}