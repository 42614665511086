import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.to,
    class: "btn preset-filled-primary-500 btn-lg transition-all flex flex-row items-center py-5"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}