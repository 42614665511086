import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center space-y-2.5 mt-4" }
const _hoisted_2 = { class: "flex flex-row justify-between mt-6" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-error-600 text-center py-3 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filled_text_field = _resolveComponent("filled-text-field")!
  const _component_primary_button = _resolveComponent("primary-button")!
  const _component_basic_button = _resolveComponent("basic-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "h3" }, "Sign in", -1)),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_filled_text_field, {
        modelValue: _ctx.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
        disable: _ctx.disableControls,
        placeholder: "Username"
      }, null, 8, ["modelValue", "disable"]),
      _createVNode(_component_filled_text_field, {
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
        disable: _ctx.disableControls,
        placeholder: "Password",
        type: "password"
      }, null, 8, ["modelValue", "disable"])
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_primary_button, {
        disable: _ctx.disableControls,
        onClick: _ctx.signIn
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Sign in")
        ])),
        _: 1
      }, 8, ["disable", "onClick"]),
      _createVNode(_component_basic_button, {
        disable: _ctx.disableControls,
        onClick: _ctx.register
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Register")
        ])),
        _: 1
      }, 8, ["disable", "onClick"])
    ]),
    (_ctx.$store.state.user.error.isError)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$store.state.user.error.message), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}