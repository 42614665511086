<template>
  <transition name="upscale">
    <div v-if="visible"
         class="flex flex-row justify-center items-center fixed left-0 top-0 w-screen h-screen
         bg-black/40 z-20">
      <div class="card flex flex-col w-[90vw] h-[80vh] lg:w-[48rem] lg:h-[40rem] bg-surface-950 drop-shadow-lg" id="dialog">
        <section class="absolute block right-0 top-0">
          <button class="text-error-900 border border-error-900/30
          hover:bg-error-900/20 card w-10 h-10 mx-2 my-2 text-lg"
                  @click="close">
            <i class="fas fa-times"></i>
          </button>
        </section>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Dialog',
  props: {
    visible: Boolean,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
.upscale-enter-active #dialog,
.upscale-leave-active #dialog,
.upscale-enter-active,
.upscale-leave-active {
  @apply transition-all;
}

.upscale-enter-from #dialog,
.upscale-leave-to #dialog {
  @apply transform scale-90 opacity-0;
}

.upscale-enter-from,
.upscale-leave-to {
  @apply bg-black/0;
}
</style>
