import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-surface-100-900 h-[calc(100vh-80px)] sm:h-[calc(100vh-100px)] overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col max-w-[26rem] m-auto mt-4 px-5"
}
const _hoisted_3 = { class: "flex flex-col gap-4 mt-4" }
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col mt-4 m-auto max-w-[26rem] px-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_titled_filled_field = _resolveComponent("titled-filled-field")!
  const _component_primary_button = _resolveComponent("primary-button")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (!_ctx.success)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "h3" }, "Reset password", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "h6 opacity-60" }, "Enter a new password for your account", -1)),
          _createElementVNode("section", _hoisted_3, [
            _createVNode(_component_titled_filled_field, {
              title: "Password",
              "field-type": "password",
              placeholder: "Password",
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_titled_filled_field, {
              title: "Confirm password",
              "field-type": "password",
              placeholder: "Confirm password",
              modelValue: _ctx.verifyPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.verifyPassword) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_primary_button, {
              class: "mt-2",
              onClick: _ctx.resetPassword
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Reset password")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("section", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "h4" }, "Password reset successfully", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "h6 opacity-60" }, "Please log in with your new password", -1)),
          _createVNode(_component_primary_button, {
            class: "mt-4",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/')))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Homepage")
            ])),
            _: 1
          })
        ]))
  ]))
}