<template>
	<input class="input p-2 min-w-28 lg:w-auto text-sm lg:text-base"
         :placeholder="placeholder" :value="value" ref="input" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'text-field',
	props: {
		placeholder: String,
		value: String,
	},
	methods: {
		selectText() {
			const input = this.$refs.input as HTMLInputElement;
			input.select();
		},
		selectAndCopy() {
			this.selectText();
			/* TODO: figure out alt for this deprecated method */
			document.execCommand('copy');
		},
	},
});
</script>

<style scoped>
</style>
