<template>
<div class="card bg-surface-950 p-5 absolute top-8 right-0 flex flex-col items-center z-40 transition-[height] ease-in-out duration-400"
	:style="rootStyle">
	<transition :name="transitionName" mode="out-in">
		<sign-in-dialog-content v-if="!isRegistering" @register="register" key="sign-in" />
		<register-dialog-content v-else @cancel-register="cancelRegister" key="register" />
	</transition>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
	import RegisterDialogContent from '@/ui/components/core/dialogs/register_dialog_content.vue';
import SignInDialogContent from '@/ui/components/core/dialogs/sign_in_dialog_content.vue';

export default defineComponent({
  name: 'AuthDialog',
  components: {
    SignInDialogContent,
    RegisterDialogContent,
  },
  data() {
    return {
      isRegistering: false,
    };
  },
  computed: {
    transitionName() {
      if (this.isRegistering) {
        return 'down-down';
      }

      return 'up-up';
    },
    rootStyle() {
      let extra = 0;
      if (this.$store.state.user.error.isError) {
        extra = 30;
      }

      if (this.isRegistering) {
        return `height: ${332 + extra}px`;
      }
      return `height: ${232 + extra}px`;
    },
  },
  methods: {
    register() {
      this.$store.commit('clearUserError');
      this.isRegistering = true;
    },
    cancelRegister() {
      this.$store.commit('clearUserError');
      this.isRegistering = false;
    },
  },
});
</script>

<style scoped>

/* Transitions */

/* Down - down (down when opening and closing) */
.down-down-enter-active {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out !important;
}

.down-down-leave-active {
  transition: transform 0.2s ease-in, opacity 0.2s ease-in !important;
}

.down-down-enter-from {
  transform: translateY(-20px) !important;
  opacity: 0 !important;
}

.down-down-leave-to {
  transform: translateY(20px) !important;
  opacity: 0 !important;
}

/* Up - up (up when opening and closing)*/
.up-up-enter-active {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out !important;
}

.up-up-leave-active {
  transition: transform 0.2s ease-in, opacity 0.2s ease-in !important;
}

.up-up-enter-from {
  transform: translateY(20px) !important;
  opacity: 0 !important;
}

.up-up-leave-to {
  transform: translateY(-20px) !important;
  opacity: 0 !important;
}
</style>
