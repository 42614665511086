<template>
<transition name="slide-out-left-fade">
    <div v-if="visible">
		<section class="absolute w-[43px] h-[43px]">
			<svg width="43px" height="43px" viewBox="0 0 43 43">
				<path class="stroke-primary-900 fill-surface-900 origin-center stroke-[3]
						transform -scale-y-100 translate-x-[1px] -translate-y-[1px]"
                :d="fullCirclePath" />
				<path class="stroke-primary-600 fill-transparent origin-center stroke-[3]
							circle-transition transform -scale-y-100 translate-x-[1px] -translate-y-[1px]"
                :d="path" />
			</svg>
		</section>
		<section class="absolute w-[43px] h-[43px] flex flex-row justify-center items-center
						text-sm font-semibold">
			<p>{{ progress }}</p><p>%</p></section>
    </div>
</transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const radius = 20;
const circlePartSize = 3.125;

export default defineComponent({
  name: 'FileProgressBar',
  props: {
    visible: Boolean,
    progress: Number,
  },
  computed: {
    path(): string {
      if (this.$props.progress === undefined) return '';
      const { progress } = this.$props;

      return this.calculatePath(progress);
    },
    fullCirclePath(): string {
      return this.calculatePath(100);
    },
  },
  methods: {
    percentageToRadians(px: number): number {
      return (px / 100) * Math.PI * 2;
    },
    radiansToPos(rads: number): { x: number; y: number; } {
      return { x: radius + radius * Math.sin(rads), y: radius + radius * Math.cos(rads) };
    },
    calculatePart(startPx: number, endPx: number): string {
      const centerPx = endPx - ((endPx - startPx) / 2);
      const { x: startX, y: startY } = this.radiansToPos(this.percentageToRadians(startPx));
      const { x: centerX, y: centerY } = this.radiansToPos(this.percentageToRadians(centerPx));
      const { x: endX, y: endY } = this.radiansToPos(this.percentageToRadians(endPx));

      return `Q${centerX},${centerY} ${endX},${endY}`;
    },
    calculatePath(progress: number): string {
      const numberOfParts = Math.ceil(progress / circlePartSize);
      const partsStr = Array(numberOfParts).fill(0)
        .map((_, i) => {
          const startPx = circlePartSize * i;
          const endPx = i < (numberOfParts - 1) ? circlePartSize * (i + 1) : progress;
          return this.calculatePart(startPx, endPx);
        })
        .join(' ');

      return `M${radius},${radius * 2} ${partsStr}`;
    },
  },
});
</script>

<style scoped>
/* Slide out (from 0) from the left and fade in */
.slide-out-left-fade-move,
.slide-out-left-fade-enter-active,
.slide-out-left-fade-leave-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, width 0.3s ease-out !important;
  opacity: 0 !important;
}

.slide-out-left-fade-enter-from,
.slide-out-left-fade-leave-to {
  transform: translateX(-62px) !important;
  width: 0 !important;
  opacity: 0 !important;
}

.circle-transition {
  transition: all 0.1s linear;
}
</style>
