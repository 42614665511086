<template>
<main class="w- max-h-screen" @drop="onDrop"
	@dragover="cancelEvent" @dragleave="cancelEvent">
	<div class="fixed bg-gradient-radial from-surface-500 via-primary-500 via-85% opacity-20 blur inset-1 z-[-2]">
	</div>
	<div :style="{backgroundImage:`url(${require('@/assets/bg_buzz.png')})`}" class="absolute inset-0 bg-repeat opacity-[0.04] -z-10">	
	</div>
	<main-header/>
	<router-view/>
	<notification-container />
</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainHeader from '@/ui/components/core/headers/main_header.vue';
import NotificationContainer from '@/ui/components/core/notifications/notification_container.vue';

export default defineComponent({
  components: { NotificationContainer, MainHeader },
  computed: {
    isViewingDrop(): boolean {
      return this.$store.state.drop.drop !== null;
    },
  },
  methods: {
    async onDrop(e: DragEvent) {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer === null) return;
      if (this.$route.path !== '/' && !this.$store.getters.isDropActive) return;

      const { files } = e.dataTransfer;

      if (!this.$store.getters.isDropActive) {
        await this.$store.dispatch('createDrop');
      }

      await this.$store.dispatch('uploadFiles', files);
    },
    cancelEvent(e: DragEvent) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
});
</script>

<style scoped>
.background {
  background-image: linear-gradient(#4527A0, #512DA8);
}
</style>
