<template>
  <a :href="to" class="btn preset-filled-primary-500 btn-lg transition-all flex flex-row items-center py-5">
    <slot />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrimaryButtonLink',
  props: {
    to: String,
  },
});
</script>
