import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full h-20 flex flex-row justify-between" }
const _hoisted_2 = { class: "flex flex-cols items-center ml-[21px] gap-5" }
const _hoisted_3 = { href: "/" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "relative mr-4 my-auto" }
const _hoisted_6 = {
  key: 0,
  class: "fas fa-user-circle mr-2"
}
const _hoisted_7 = {
  key: 1,
  class: "fas fa-times"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_dialog = _resolveComponent("auth-dialog")!
  const _component_user_dialog = _resolveComponent("user-dialog")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createElementVNode("img", {
          src: require('@/assets/FD-new-512x512.png'),
          height: "25",
          width: "25",
          class: "inline"
        }, null, 8, _hoisted_4)
      ]),
      (_ctx.dropId)
        ? (_openBlock(), _createElementBlock("h1", {
            key: 0,
            class: "h2 inline",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyLink && _ctx.copyLink(...args)))
          }, "/" + _toDisplayString(_ctx.dropId), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        class: _normalizeClass(_ctx.userButtonStyle),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSignInClick && _ctx.onSignInClick(...args)))
      }, [
        (!_ctx.authDialogVisible)
          ? (_openBlock(), _createElementBlock("i", _hoisted_6))
          : (_openBlock(), _createElementBlock("i", _hoisted_7)),
        _createTextVNode(" " + _toDisplayString(_ctx.userButtonText), 1)
      ], 2),
      _createVNode(_Transition, { name: "opacity" }, {
        default: _withCtx(() => [
          (this.authDialogVisible && !this.isSignedIn)
            ? (_openBlock(), _createBlock(_component_auth_dialog, { key: 0 }))
            : (this.authDialogVisible && this.isSignedIn)
              ? (_openBlock(), _createBlock(_component_user_dialog, {
                  key: 1,
                  onOptionSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.authDialogVisible = false))
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}