import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_list_item = _resolveComponent("file-list-item")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "group-upscale",
    tag: "ul",
    class: "divide-y divide-surface-600"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
        return (_openBlock(), _createBlock(_component_file_list_item, {
          key: file.dropFile.filename,
          "drop-file": file
        }, null, 8, ["drop-file"]))
      }), 128))
    ]),
    _: 1
  }))
}