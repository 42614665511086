import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-center items-center w-7 h-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(`btn flex flex-row items-center gap-x-2 h-14
				hover:cursor-pointer
				hover:${_ctx.containerHoverColor} active:${_ctx.containerActiveColor}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("i", {
        class: _normalizeClass(`${_ctx.icon}`)
      }, null, 2)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}