<template>
<transition name="upscale-down">
    <div class="card absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                preset-tonal-surface w-80 sm:w-96 flex flex-col gap-y-5 px-8 pt-4 pb-4
                shadow-lg" v-if="visible"
	>
		<section class="flex flex-col gap-y-2">
			<h1 class="h3">{{ $props.title }}</h1>
			<p class="">{{ $props.text }}</p>
		</section>
		<section class="flex flex-row gap-x-4 justify-end h-10 items-stretch">
			<primary-button
			v-if="!$props.destructive" @click="confirm">{{ $props.confirmTitle }}</primary-button>
			<primary-button-error v-else @click="confirm">{{ $props.confirmTitle }}</primary-button-error>
			<basic-button @click="cancel">Cancel</basic-button>
		</section>
    </div>
</transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import PrimaryButtonError from '@/ui/components/core/buttons/primary_button_error.vue';

export default defineComponent({
  name: 'ConfirmDialog',
  components: { PrimaryButtonError, BasicButton, PrimaryButton },
  props: {
    title: String,
    text: String,
    destructive: {
      type: Boolean,
      default: false,
    },
    confirmTitle: {
      type: String,
      default: 'Confirm',
    },
    visible: Boolean,
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel-confirm');
    },
  },
});
</script>
