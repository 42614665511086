<template>
<Dialog :visible="previewState.kind !== 'hidden'" @close="$store.dispatch('hideFilePreview')">
    <section v-if=" previewState.kind === 'loaded'"
			class="flex flex-col w-full h-full px-3 pt-2 pb-4"
		>
		<h1 class="h4">Preview</h1>
		<h3 class="opacity-60">{{ previewState.filename }}</h3>
		<textarea class="card flex-grow w-full text-sm lg:text-base mt-4 font-jb text-nowrap
			outline-none px-1 py-1 bg-surface-900" v-model="previewState.content" :disabled="true"/>
    </section>

    <section v-else-if="previewState.kind === 'error'"
			class="flex flex-col justify-center items-center w-full h-full space-y-3"
	>
		<i class="fas fa-exclamation-triangle fa-3x "></i>
		<h6>{{ previewState.message ?? 'Error occurred while loading preview' }}</h6>
    </section>

    <section v-else class="flex flex-col justify-center items-center h-full w-full space-y-3" >
		<progress-spinner :size="10"/>
		<h6>Loading preview</h6>
    </section>
</Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FilePreviewState } from '@/interfaces/store/drop';
import ProgressSpinner from '@/ui/components/core/progress_bars/progress_spinner.vue';
import Dialog from '@/ui/components/core/dialogs/dialog.vue';

export default defineComponent({
  name: 'FilePreviewDialog',
  components: {
    Dialog,
    ProgressSpinner,
  },
  computed: {
    previewState(): FilePreviewState {
      return this.$store.state.drop.previewState;
    },
  },
});
</script>

<style scoped>

</style>
