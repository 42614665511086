import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full bg-surface-100-900 px-2 sm:px-6 lg:px-12 py-3 h-[calc(100vh-80px)] sm:h-[calc(100vh-100px)] overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "relative h-full max-w-[1400px] mx-auto"
}
const _hoisted_3 = { class: "overflow-y-auto overflow-x-hidden sm:h-[calc(100%-80px)] h-[calc(100%-100px)]" }
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col justify-center items-center h-full space-y-3"
}
const _hoisted_5 = { class: "h6" }
const _hoisted_6 = {
  key: 2,
  class: "flex flex-col justify-center items-center h-full w-full space-y-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop_header = _resolveComponent("drop-header")!
  const _component_drop_file_list = _resolveComponent("drop-file-list")!
  const _component_file_preview_dialog = _resolveComponent("file-preview-dialog")!
  const _component_primary_button = _resolveComponent("primary-button")!
  const _component_progress_spinner = _resolveComponent("progress-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDropLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_drop_header, {
            "drop-id": _ctx.drop.id
          }, null, 8, ["drop-id"]),
          _createElementVNode("section", _hoisted_3, [
            _createVNode(_component_drop_file_list, { files: _ctx.files }, null, 8, ["files"])
          ]),
          _createVNode(_component_file_preview_dialog)
        ]))
      : (_ctx.dropError.isError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-exclamation-triangle fa-3x" }, null, -1)),
            _createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.dropError.message ?? 'Error occurred while loading drop'), 1),
            _createVNode(_component_primary_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/')))
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Go to homepage")
              ])),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_progress_spinner, { size: 10 }),
            _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "h6" }, "Loading drop", -1))
          ]))
  ]))
}