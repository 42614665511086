<template>
<main class="bg-surface-100-900 h-[calc(100vh-80px)] sm:h-[calc(100vh-100px)] overflow-hidden">
	<div class="card grid grid-rows-[1fr,auto] md:grid-cols-[1fr,3fr] md:grid-rows-1 px-5 py-10 gap-10 border-x-2 border-surface-700 m-auto w-[95%] sm:w-3/4 h-full">
		<profile-sidebar class="md:max-w-[300px]" />
		<section class="overflow-y-auto">
			<div v-if="!$store.getters.isLoggedIn" class="flex-grow max-w-[72rem]">
				<div class="flex flex-col w-full items-center gap-y-3 mt-16">
					<i class="fas fa-exclamation-triangle fa-3x"></i>
					<h6 class="h6">You have to sign in to access this page</h6>
				</div>
			</div>

			<profile-section v-else 
				:title="profileTitle"
				:subtitle="profileSubtitle"
			>
				<account-settings-section v-if="currentRouteName === 'accountSettings'" />
				<my-drops-section v-if="currentRouteName === 'myDrops'" />
			</profile-section>
		</section>
	</div>
</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileSidebar from '@/ui/components/profile/sidebar/profile_sidebar.vue';
import ProfileSection from '@/ui/components/profile/profile_section.vue';
import AccountSettingsSection from '@/ui/components/profile/account_settings/account_settings_section.vue';
import MyDropsSection from '@/ui/components/profile/drops/my_drops.vue';

export default defineComponent({
	name: 'Profile',
	components: {
		ProfileSection,
		ProfileSidebar,
		MyDropsSection,
		AccountSettingsSection
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
		profileTitle() {
			return (this.$route.name === 'accountSettings') ? 'Account settings' : 'My drops';
		},
		profileSubtitle() {
			return (this.$route.name ===  'accountSettings') ? 'Manage your account settings' : 'All currently active drops';
		},
	},
	mounted() {
		if (!this.$store.getters.isLoggedIn) {
			this.$router.push('/');
		}
	},
});
</script>
