<template>
<button :disabled="disable" 
		class="btn btn-md preset-filled-primary-500 transition-all disabled:bg-primary-950 disabled:text-opacity-50 disabled:brightness-75 disabled:cursor-not-allowed">
	<slot />
</button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrimaryButton',
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
