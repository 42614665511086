<template>
<main class="h-[calc(100vh-95px)] grid place-items-center">

	<div v-if="!isViewingDrop" class="grid grid-rows-[2fr,1fr,1.5fr,2fr] h-min mb-14">
		<i class="fas fa-file-upload text-center text-4xl self-center"></i>
		<p class="font-light text-xl text-center">Drop files here</p>
		<p class="font-light text-xl text-center">or</p>
		<button 
			class="btn preset-outlined-primary-400-600 font-light text-xl text-center p-7 py-10 mx-auto transition-all" 
			@click="openFileExplorer"
		>
			<b class="font-bold -mr-2">Select file</b>from device
		</button>
		<input type="file" class="hidden" ref="fileInput" @change="onFileChange" multiple>
	</div>

	<transition name="height">
		<drop-container v-if="isViewingDrop" />
	</transition>

</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DropContainer from '@/ui/components/core/containers/drop_container.vue';

export default defineComponent({
  name: 'home',
  components: { DropContainer },
  mounted() {
    if ('dropId' in this.$route.params) {
      this.$store.dispatch('fetchDrop', this.$route.params.dropId);
    }
  },
  computed: {
    isViewingDrop(): boolean {
      return 'dropId' in this.$route.params;
    },
	dropId(): string {
		return this.$route.params.dropId as string;
	},
  },
  methods: {
    openFileExplorer() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.value = '';
      fileInput.click();
    },
    async onFileChange(e: Event) {
      if (!this.$store.getters.isDropActive) {
        await this.$store.dispatch('createDrop');
      }
      const { files } = (e.target as HTMLInputElement);
      this.$store.dispatch('uploadFiles', files);
    },
  },
});
</script>
