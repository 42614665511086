import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "h-[calc(100vh-95px)] grid place-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "grid grid-rows-[2fr,1fr,1.5fr,2fr] h-min mb-14"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop_container = _resolveComponent("drop-container")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (!_ctx.isViewingDrop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-file-upload text-center text-4xl self-center" }, null, -1)),
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "font-light text-xl text-center" }, "Drop files here", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-light text-xl text-center" }, "or", -1)),
          _createElementVNode("button", {
            class: "btn preset-outlined-primary-400-600 font-light text-xl text-center p-7 py-10 mx-auto transition-all",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openFileExplorer && _ctx.openFileExplorer(...args)))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("b", { class: "font-bold -mr-2" }, "Select file", -1),
            _createTextVNode("from device ")
          ])),
          _createElementVNode("input", {
            type: "file",
            class: "hidden",
            ref: "fileInput",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
            multiple: ""
          }, null, 544)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "height" }, {
      default: _withCtx(() => [
        (_ctx.isViewingDrop)
          ? (_openBlock(), _createBlock(_component_drop_container, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}