<template>
<li class="flex flex-col sm:flex-row w-full justify-between py-3 items-center gap-2">

	<section class="sm:max-w-[80%] text-center sm:text-left lg:max-w-auto relative">
		<file-progress-bar
			:progress="dropFile.kind === 'uploadingDropFile' ? dropFile.dropFile.progress : 0"
			:visible="dropFile.kind === 'uploadingDropFile'" /> 
		<section :class="`transition-all transform
			${dropFile.kind === 'uploadingDropFile' ? 'transform translate-x-[50px]' : ''}`"
		>
			<h2 class="h5 leading-5">{{ filename }}</h2>
			<p class="align-text-top text-center sm:text-left opacity-60">{{ displaySize }}</p> 
		</section>
	</section>

	<section class="flex flex-row gap-2 items-center"
				v-if="dropFile.kind === 'dropFile'">
		<primary-button-link :to="downloadLink">Download</primary-button-link>
		<section class="flex fle-col relative h-full">
			<basic-button @click="openContextMenu">More</basic-button>
			<transition name="fade-fast">
				<drop-file-context-menu v-if="isContextMenuOpen"
										:drop-id="dropFile.dropFile.dropId"
										:file-id="dropFile.dropFile.id" 
				/>
			</transition>
		</section>
	</section>

</li>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import DropFileContextMenu from '@/ui/components/core/context_menus/drop_file_context_menu.vue';
import { AnyDropFile } from '@/interfaces/store/drop';
import PrimaryButtonLink from '@/ui/components/core/links/primary_button_link.vue';
import FileProgressBar from '@/ui/components/core/progress_bars/file_progress_bar.vue';

export default defineComponent({
  name: 'FileListItem',
  components: {
    FileProgressBar,
    PrimaryButtonLink,
    DropFileContextMenu,
    BasicButton,
  },
  props: {
    dropFile: {
      type: Object as PropType<AnyDropFile>,
      required: true,
    },
  },
  computed: {
    filename() {
      return this.$props.dropFile.dropFile.filename;
    },
    displaySize(): string {
      return this.getReadableSize(this.$props.dropFile.dropFile.fileSize);
    },
    isContextMenuOpen(): boolean {
      const { contextMenuState } = this.$store.state.drop;
      if (contextMenuState == null) return false;

      return this.dropFile.kind === 'dropFile'
        && contextMenuState.dropId === this.dropFile.dropFile.dropId
        && contextMenuState.fileId === this.dropFile.dropFile.id;
    },
    downloadLink(): string {
      if (this.$props.dropFile.kind !== 'dropFile') return '';

      const { id, dropId } = this.$props.dropFile.dropFile;
      return `${process.env.VUE_APP_API_URL}/drops/${dropId}/files/${id}/content`;
    },
  },
  methods: {
    getReadableSize(size: number): string {
      const bytes = size;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.round(Math.floor(Math.log(bytes) / Math.log(1024)));
      return `${Math.round(bytes / (1024 ** i))} ${sizes[i]}`;
    },
    openContextMenu() {
      if (this.dropFile.kind !== 'dropFile') return;

      if (!this.isContextMenuOpen) {
        this.$store.commit('openFileContextMenu', {
          dropId: this.dropFile.dropFile.dropId,
          fileId: this.dropFile.dropFile.id,
        });
      } else {
        this.$store.commit('closeFileContextMenu');
      }
    },
  },
});
</script>
