import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "h6" }
const _hoisted_3 = { class: "pl-4 sm:justify-self-end w-full lg:w-4/5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$props.title), 1),
    _createElementVNode("section", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}