<template>
<li class="flex flex-col text-center sm:text-left sm:flex-row w-full justify-between py-3 gap-y-2">
	<section class="flex flex-col transition-all">
		<h2 class="h4 sm:h5">{{ dropId }}</h2>
		<p class="align-text-top leading-4 opacity-60">Created on: {{ createDate.toLocaleString('en-uk') }}</p>
	</section>
	<section class="flex flex-col sm:flex-row gap-2 sm:items-center">
		<primary-button-link :to="link">View</primary-button-link>
		<basic-button-error @click="deleteDrop">Delete</basic-button-error>
	</section>
</li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PrimaryButtonLink from '@/ui/components/core/links/primary_button_link.vue';
import BasicButtonError from '@/ui/components/core/buttons/basic_button_error.vue';

export default defineComponent({
	name: 'MyDropListItem',
	components: { BasicButtonError, PrimaryButtonLink },
	props: {
		dropId: String,
		createDate: Date,
		link: String,
	},
	methods: {
		deleteDrop() {
			this.$store.dispatch('deleteFromMyDrops', this.dropId);
		},
	},
});
</script>

<style scoped>

</style>
