<template>
<transition-group name="group-upscale" tag="ul" class="divide-y divide-surface-600">
	<file-list-item v-for="file in files" :key="file.dropFile.filename" :drop-file="file" />
</transition-group>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DropFile } from '@/interfaces/models/drop_file';
import FileListItem from '@/ui/components/core/list_items/file_list_item.vue';
import { AnyDropFile } from '@/interfaces/store/drop';

export default defineComponent({
  name: 'drop-file-list',
  components: { FileListItem },
  props: {
    files: Object as PropType<AnyDropFile[]>,
  },
});
</script>
