import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notification_container = _resolveComponent("notification-container")!

  return (_openBlock(), _createElementBlock("main", {
    class: "w- max-h-screen",
    onDrop: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args))),
    onDragover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelEvent && _ctx.cancelEvent(...args))),
    onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancelEvent && _ctx.cancelEvent(...args)))
  }, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fixed bg-gradient-radial from-surface-500 via-primary-500 via-85% opacity-20 blur inset-1 z-[-2]" }, null, -1)),
    _createElementVNode("div", {
      style: _normalizeStyle({backgroundImage:`url(${require('@/assets/bg_buzz.png')})`}),
      class: "absolute inset-0 bg-repeat opacity-[0.04] -z-10"
    }, null, 4),
    _createVNode(_component_main_header),
    _createVNode(_component_router_view),
    _createVNode(_component_notification_container)
  ], 32))
}