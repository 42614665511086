<template>
<header class="w-full h-20 flex flex-row justify-between">
	<div class="flex flex-cols items-center ml-[21px] gap-5">
		<a href="/"><img :src="require('@/assets/FD-new-512x512.png')" height="25" width="25" class="inline" /></a> 
		<h1 v-if="dropId" class="h2 inline" @click="copyLink">/{{ dropId }}</h1>
	</div> 
	<div class="relative mr-4 my-auto">
		<a :class="userButtonStyle" @click="onSignInClick">
			
			<i v-if="!authDialogVisible" class="fas fa-user-circle mr-2"></i>
			<i v-else class="fas fa-times"></i>
			{{ userButtonText }}
		</a>
		<transition name="opacity">
			<auth-dialog v-if="this.authDialogVisible && !this.isSignedIn" />
			<user-dialog v-else-if="this.authDialogVisible && this.isSignedIn"
						@option-select="authDialogVisible = false" />
		</transition>
	</div>
</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AuthDialog from '@/ui/components/core/dialogs/auth_dialog.vue';
import UserDialog from '@/ui/components/core/dialogs/user_dialog.vue';

export default defineComponent({
	name: 'main-header',
	components: { UserDialog, AuthDialog },
	data() {
		return {
			authDialogVisible: false,
		};
	},
	computed: {
		isSignedIn(): boolean {
			return this.$store.getters.isLoggedIn;
		},
		userButtonText(): string {
			if(this.authDialogVisible) return '';
			return this.$store.getters.isLoggedIn
				? this.$store.state.user.currentUser.username
				: 'Sign in';
		},
		userButtonStyle(): string {
			let base = 'btn preset-ghost hover:preset-tonal cursor-pointer select-none gap-1 h6 min-w-[120px]'
			return this.authDialogVisible ? (base + ' bg-surface-950'): base;
		},
		dropId(): string {
			return this.$route.params.dropId as string;
		},
		link() {
			return `${process.env.VUE_APP_URL}/${this.dropId}`;
		},
	},
	methods: {
		onSignInClick() {
			this.authDialogVisible = !this.authDialogVisible;
		},
		async copyLink() {
			await navigator.clipboard.writeText(this.link);
		},
	},
});
</script>

<style scoped>
.button-bg-hover:hover {
  background-color: rgba(119, 118, 118, 0.22);
}

.opacity-enter-active,
.opacity-leave-active {
  @apply transition-opacity;
}
.opacity-enter-from,
.opacity-leave-to {
  @apply opacity-0;
}
</style>
