<template>
<main class="flex flex-col flex-grow max-w-[72rem]">
	<h1 class="h4">{{ $props.title }}</h1>
	<h3 class="opacity-60">{{ $props.subtitle }}</h3>
	<section class="mt-4">
		<slot />
	</section>
</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileSection',
  props: {
    title: String,
    subtitle: String,
  },
});
</script>

<style scoped>

</style>
