<template>
  <button class="btn preset-tonal-error btn-md transition-all">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrimaryButtonError',
  props: {

  },
});
</script>
