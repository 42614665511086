<template>
<section class="flex flex-row gap-x-2 items-end items">
	<titled-filled-field :title="$props.title" v-model="value" :disable="!editing" />
	<section v-if="!$props.editing" class="flex flex-row items-stretch h-10">
		<basic-button class="py-2 mb-0.5" @click="edit">Edit</basic-button>
	</section>
	<section class="flex flex-row gap-x-2 items-stretch h-10" v-else>
		<primary-button class="py-2 mb-0.5" @click="save">Save</primary-button>
		<basic-button class="py-2 mb-0.5" @click="cancel">Cancel</basic-button>
	</section>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TitledFilledField from '@/ui/components/core/text_fields/titled_filled_field.vue';
import BasicButton from '@/ui/components/core/buttons/basic_button.vue';
import PrimaryButton from '@/ui/components/core/buttons/primary_button.vue';

export default defineComponent({
  name: 'AccountEditField',
  components: { PrimaryButton, BasicButton, TitledFilledField },
  props: {
    title: String,
    modelValue: String,
    editing: Boolean,
  },
  data() {
    return {
      value: this.$props.modelValue,
    };
  },
  watch: {
    modelValue(newValue: string, oldValue: string) {
      this.value = newValue;
    },
    value(newValue: string, oldValue: string) {
      this.$emit('update:modelValue', newValue);
    },
  },
  methods: {
    edit() {
      this.$emit('edit');
    },
    cancel() {
      this.$emit('cancel');
    },
    save() {
      this.$emit('save');
    },
  },
});
</script>

<style scoped>

</style>
