import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-surface-100-900 h-[calc(100vh-80px)] sm:h-[calc(100vh-100px)] overflow-hidden" }
const _hoisted_2 = { class: "card grid grid-rows-[1fr,auto] md:grid-cols-[1fr,3fr] md:grid-rows-1 px-5 py-10 gap-10 border-x-2 border-surface-700 m-auto w-[95%] sm:w-3/4 h-full" }
const _hoisted_3 = { class: "overflow-y-auto" }
const _hoisted_4 = {
  key: 0,
  class: "flex-grow max-w-[72rem]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_sidebar = _resolveComponent("profile-sidebar")!
  const _component_account_settings_section = _resolveComponent("account-settings-section")!
  const _component_my_drops_section = _resolveComponent("my-drops-section")!
  const _component_profile_section = _resolveComponent("profile-section")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_profile_sidebar, { class: "md:max-w-[300px]" }),
      _createElementVNode("section", _hoisted_3, [
        (!_ctx.$store.getters.isLoggedIn)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "flex flex-col w-full items-center gap-y-3 mt-16" }, [
                _createElementVNode("i", { class: "fas fa-exclamation-triangle fa-3x" }),
                _createElementVNode("h6", { class: "h6" }, "You have to sign in to access this page")
              ], -1)
            ])))
          : (_openBlock(), _createBlock(_component_profile_section, {
              key: 1,
              title: _ctx.profileTitle,
              subtitle: _ctx.profileSubtitle
            }, {
              default: _withCtx(() => [
                (_ctx.currentRouteName === 'accountSettings')
                  ? (_openBlock(), _createBlock(_component_account_settings_section, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.currentRouteName === 'myDrops')
                  ? (_openBlock(), _createBlock(_component_my_drops_section, { key: 1 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title", "subtitle"]))
      ])
    ])
  ]))
}