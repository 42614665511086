<template>
<button :disabled="disable"
		:class="style()"
		>
	<slot />
</button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BasicButton',
	props: {
		disable: {
			type: Boolean,
			default: false,
		},
		toggled: {
			type: Boolean,
			default: false,
		}
	},
	mounted() {
		if (this.toggled) {
			//this.$el.focus();
		}
	},
	methods: {
		style() {
			let base = 'btn btn-md transition-colors ' +
			'hover:preset-filled-primary-500 hover:brightness-100 ' +
			'focus:preset-filled-primary-500 focus:cursor-default active:preset-filled-primary-700 ' + 
			'active:brightness-100 ' +
			'disabled:bg-primary-950 disabled:text-opacity-50 disabled:brightness-75 disabled:cursor-not-allowed';

			return this.toggled ? base + ' preset-filled-primary-500 cursor-default shadow-inner shadow-surface-100-900' : base;
		},
	},
});
</script>

<style scoped>

</style>
